//Breakpoints
@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

//máxima anchura
@mixin max-width{
	width: 96%;
	max-width: $maxwidth-page;
}

//Centrar elemento
@mixin push-auto {
    margin: {
        left: auto;
        right: auto;
    }
}

//imagen dentro de bloque
@mixin img{
    max-width: 100%;
    height: auto;
    display: block;
}

//centrar verticalmente en absoluto
@mixin v-align{
	position: absolute;
	top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

//Radio de borde
@mixin rounded($radio) {
    -webkit-border-radius: $radio;
    -moz-border-radius: $radio;
    -ms-border-radius: $radio;
    -o-border-radius: $radio;
    border-radius: $radio;
}

//Transiciones css
@mixin transition($val) {
    -webkit-transition: $val;
    -moz-transition: $val;
    -ms-transition: $val;
    -o-transition: $val;
    transition: $val;
}

//Transforms
@mixin transform($transforms) {
    -moz-transform: $transforms;
      -o-transform: $transforms;
     -ms-transform: $transforms;
    -webkit-transform: $transforms;
     transform: $transforms;
}
@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}
@mixin scale($scale) {
    @include transform(scale($scale));
}
@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}
@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
     transform-origin: $origin;
}

//Flexbox
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
@mixin flex($values) {
-webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
        -ms-flex: $values;
            flex: $values;
}
@mixin flex-direction($direction) {
-webkit-flex-direction: $direction;
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
        flex-direction: $direction;
}
@mixin flex-wrap($wrap) {
-webkit-flex-wrap: $wrap;
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
        flex-wrap: $wrap;
}
@mixin flex-flow($flow) {
-webkit-flex-flow: $flow;
    -moz-flex-flow: $flow;
    -ms-flex-flow: $flow;
        flex-flow: $flow;
}
@mixin order($val) {
-webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
            -ms-flex-order: $val;
            -webkit-order: $val;
                order: $val;
}
@mixin flex-grow($grow) {
-webkit-flex-grow: $grow;
    -moz-flex-grow: $grow;
    -ms-flex-grow: $grow;
        flex-grow: $grow;
}
@mixin flex-shrink($shrink) {
-webkit-flex-shrink: $shrink;
    -moz-flex-shrink: $shrink;
    -ms-flex-shrink: $shrink;
        flex-shrink: $shrink;
}
@mixin flex-basis($width) {
-webkit-flex-basis: $width;
    -moz-flex-basis: $width;
    -ms-flex-basis: $width;
        flex-basis: $width;
}
@mixin justify-content($justify) {
-webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
        justify-content: $justify;
            -ms-flex-pack: $justify;
}
@mixin align-content($align) {
-webkit-align-content: $align;
    -moz-align-content: $align;
    -ms-align-content: $align;
        align-content: $align;
}
@mixin align-items($align) {
-webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
        align-items: $align;
}
@mixin align-self($align) {
-webkit-align-self: $align;
    -moz-align-self: $align;
    -ms-align-self: $align;
        align-self: $align;
}

//dar clear, limpiar, un elemento
@mixin clearfix{
	*zoom: 1;
    &::before, 
    &::after{
		content: "";
		display: table;
	}
	&::after{
		clear: both;
	}
}

//ocultar texto 
@mixin hide-text{
	text-indent: -999em;
	white-space: nowrap;
	overflow: hidden;
}
//hacer texto visible
@mixin visible-text{
	text-indent: 0;
	white-space: normal;
	overflow: visible;
}

//ocultar elementos en sr también
@mixin hide{
	visibility: hidden;
	display: none;
}
//mostrar elementos en sr también
@mixin show(){
    visibility: visible;
	display: block;
}

//limitar texto
@mixin overflow-text{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

//opacidad
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
  }

//Placeholders
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}