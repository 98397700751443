//títulos
h2{
    font-family: $font-base;
    &.main-fain-block__title{
        width: 98%;
        max-width: $maxwidth-page;
        margin-bottom: 0.5em;
        margin-left: auto;
        margin-right: auto;
        font-size: em(30);
        line-height: 110%;
        text-align: center;
        color: $font-dark;
        &::first-letter{
            text-transform: uppercase;
        }
        //media queries
        @include mq('phablet'){
            font-size: em(44);
            text-align: left;
        }
        @include mq('desktop'){
            font-size: em(64);
        }
        &.bg-dark{
            padding: 0;
            background-color: #eee;
            color: $font-light;
            span{
                height: 100%;
                display: inline-block;
                padding: $margin / 2;
                background-color: $bg-dark;
            }
        }
    }
}
h3{
    @extend h2;
    &.main-fain-block__subtitle{
        font-family: $font-bold;
        font-size: em(23);
         //media queries
         @include mq('phablet'){
            font-size: em(30);
        }
        @include mq('desktop'){
            font-size: em(38);
            text-align: left;
        }
    }
}
//párrafos
p{
    font-family: $font-base; 
    color: $font-dark;
    line-height: 120%; 
}
.main-fain-block__text{
    margin-top: $margin / 2;
    margin-bottom: $margin / 2;
    font-size: em(20);
    text-align: center;
    //media queries
    @include mq('tablet'){
        font-size: em(24);
        text-align: left;
    }
    @include mq('desktop'){
        font-size: em(30);
    }
    &.big{
        font-size: em(26);
        //media queries
        @include mq('tablet'){
            font-size: em(44);
        }
        @include mq('desktop'){
            font-size: em(64);
        }
    }
    &.med{
        font-size: em(23);
        //media queries
        @include mq('tablet'){
            font-size: em(33);
        }
        @include mq('desktop'){
            font-size: em(43);
        }
    }
    &.small{
        margin-bottom: 0.5em;
        font-size: em(20);
         //media queries
         @include mq('tablet'){
            font-size: em(30);
        }
        @include mq('desktop'){
            font-size: em(40);
        }
    }
    &.list{
        text-align: center;
    }
}


