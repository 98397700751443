//Breakpoints
$breakpoints: (
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      560px,
    "tablet-small": 640px,
    "tablet":       768px,
    "tablet-medium":992px,
    "tablet-wide":  1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);

//Tamaño fuente por defecto para pasar a em
$browser-context: 16;

//Anchura máxima página
$maxwidth-page: 1400px;

//Valor base de margen y padding
$margin: 1em;
$padding: $margin;

//Fuentes
$font-base: 'FsJoey-Regular', sans-serif;
$font-light: 'FsJoey-Light', sans-serif;
$font-bold: 'FsJoey-Bold', sans-serif;
$font-heavy: 'FsJoey-heavy', sans-serif;
$font-size: 0.8rem;

//Colores
    $invalid-color: rgb(185, 35, 38);
    //backgrounds
    $bg-dark: rgba(40, 40, 40, 1);
    $bg-light: rgba(255, 255, 255, 1);
    $bg-yellow: rgba(243, 209, 49, 1);
    $bg-light-gray: rgba(191, 191, 191, 1);
    $bg-dark-gray: rgba(118, 118, 118, 1);
    //fuentes
    $font-dark: $bg-dark;
    $font-light: $bg-light;
    $font-yellow: $bg-yellow;
    $font-gray: $bg-light-gray;
    $font-invalid:  $invalid-color;
    //links
    $link-dark: $bg-dark;
    $link-yellow: $bg-yellow;
    //bordes
    $border-form: 1px solid rgb(144, 144, 144);
    $border-invalid: 1px solid $invalid-color;
    //placeholder
    $placeholder-color: rgb(216, 216, 216);

//Opacidad
$opacity: 0.85;

//Alturas de imágenes
$height-imgCompromiso: 760px;
$size-icoBg: 120px;
$size-icoTop: $size-icoBg;


