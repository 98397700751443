//Listas
ul,
ol{
    &.questions-list{
        //media queries
        @include mq('tablet-medium'){
           @include flexbox();
           @include justify-content(space-between);
           @include flex-wrap(wrap);
        }
        .questions-list__item{
            width: 100%;
            margin: $margin * 2 0;
            //media queries
            @include mq('tablet-medium'){
                @include flex(0 1 47%);
                margin-right: 2%;
                &:nth-child(even){
                    margin-left: 2%;
                }
            }
            span{
                float: left;
                margin-right: $margin / 2.5;
                color: $font-yellow;
            }
            //texto y link dentro de li de preguntas (los estilos por defecto están en typography y links respectivamente)
            .main-fain-block__text{
                text-align: left;
                margin: 0;
            }
            a{
                &.link-show-dropdown{
                    display: inline-block;
                }
            }
        }
    }
    //links políticas en footer
    &.footer-fain__right__bottom{
        @include flexbox();
        margin-top: $margin / 2;
        li{
            margin-right: 5px;
            a{
                font-size: em(15);
                &::after{
                    content: "|";
                    margin-left: 5px;
                }
            }
            &:last-child{
                a{
                    &::after{
                        @include hide();
                    }
                }
            }
        }
    }
}