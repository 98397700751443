.politics-and-cookies{
    margin-top: $margin * 7;
    margin-bottom: $margin * 3.5;
    .wrapper-content{
        font-family: $font-base;
        font-size: $font-size;
        //media queries
        @include mq('tablet'){
           font-size: $font-size + 0.1;
        }
        @include mq('tablet-medium'){
            font-size: $font-size + 0.2;
        }
    }
    //titulo h2
    .subtitle{
        &.politics{
            margin: $margin 0;
            font-family: $font-bold;
            font-size: em(24);
            text-transform: uppercase;
            color: $font-dark;
        }
    }
    //h3 title
    .politics-title-text{
        margin: $margin 0 $margin / 2 0;
        font-family: $font-bold;
        font-size: em(21);
    }
    //textos
    .politics-text{
        margin-bottom: $margin;
        font-size: em(19);
        line-height: 140%;
        color: $font-dark;
    }
    //subcontenedor
    .wrapper-content__intern{
        width: 95%;
        margin-left: 5%;
    }
    //h4 title
    .politics-subtitle-text{
        @extend .politics-title-text;
        font-size: em(20.5);
        text-transform: capitalize;
    }
    //links
    .politics-link{
        display: inline-block;
        color: $link-yellow;
    }
    //listas
    .politics-list{
        @include clearfix();
        margin: $margin  0;
        padding-left: 5%;
        list-style-type: circle;
        li{
            margin: $margin 0;
            font-size: em(19);
            line-height: 140%;
            a{
                margin-left: 4px;
            }
        }
        &.no-liststyle{
            list-style-type: none;
        }
    }
    //tablas
    table{
        &.politics-table{
            width: 100%;
            margin: $margin 0;
            border-collapse: collapse;
            th,
            td{
                padding: $padding $padding / 2;
                border: 1px solid $bg-dark;
                text-align: center;
            }
            th{
                font-family: $font-bold;
                text-transform: capitalize;
            }
        }
    }

}