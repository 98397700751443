//Estructura
.full{
    width: 100% !important;
}
.block{
    display: block !important;
}

//Márgenes
.marginTop{margin-top: 1em !important;}
.marginBottom{margin-bottom: 1em !important;}
.marginTop-none{margin-top: 0 !important;}
.marginBottom-none{margin-bottom: 0 !important;}

//Tipografía
.font-bold{
    font-family: $font-bold !important;
}
.font-white{
    color: $font-light !important;
}
.font-yellow{
    color: $font-yellow !important;
}
.text-right{
    text-align: right !important;
}