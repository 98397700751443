.go-top{
    position: fixed;
     z-index: 999;
     bottom: 30px; // Distancia desde abajo
     right: 8px; // Distancia desde la derecha
     .fa{
        font-size: 3rem;
        color: rgba(243, 209, 49, .4);
        //media querie
        @include mq('tablet-medium'){
            font-size: 4rem;
        }   
     }
     &:hover{
         .fa{
            color: $bg-yellow;
         }
     }
     //media querie
     @include mq('tablet-medium'){
         right: 15px;
     }   
}