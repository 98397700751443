@font-face {
    font-family: 'FsJoey Light';
    src: url('../webfonts/FSJoey-Light.otf'),
         url('../webfonts/FSJoey-Lights.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/FSJoey-Light.woff') format('woff'),
         url('../webfonts/FSJoey-Light.ttf') format('truetype'),
         url('../webfonts/FSJoey-Light-sans') format('svg');
}
@font-face {
    font-family: 'FsJoey-Regular';
    src: url('../webfonts/FSJoey-Regular.otf'),
         url('../webfonts/FSJoey-Regular.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/FSJoey-Regular.woff') format('woff'),
         url('../webfonts/FSJoey-Regular.ttf') format('truetype'),
         url('../webfonts/FSJoey-Regular-sans') format('svg');
}
@font-face {
    font-family: 'FsJoey-Medium';
    src: url('../webfonts/FSJoey-Medium.otf'),
         url('../webfonts/FSJoey-Medium.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/FSJoey-Medium.woff') format('woff'),
         url('../webfonts/FSJoey-Medium.ttf') format('truetype'),
         url('../webfonts/FSJoey-Medium-sans') format('svg');
}
@font-face {
    font-family: 'FsJoey-Bold';
    src: url('../webfonts/FSJoey-Bold.otf'),
         url('../webfonts/FSJoey-Bold.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/FSJoey-Bold.woff') format('woff'),
         url('../webfonts/FSJoey-Bold.ttf') format('truetype'),
         url('../webfonts/FSJoey-Bold-sans') format('svg');
}
@font-face {
    font-family: 'FsJoey-Heavy';
    src: url('../webfonts/FSJoey-Heavy.otf'),
         url('../webfonts/FSJoey-Heavy.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/FSJoey-Heavy.woff') format('woff'),
         url('../webfonts/FSJoey-Heavy.ttf') format('truetype'),
         url('../webfonts/FSJoey-Heavy-sans') format('svg');
}

