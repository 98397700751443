a{
    font-family: $font-base;
    &:hover{
        @include opacity($opacity);
    }
    &.fain-link{
        font-family: $font-bold;
        color: $link-dark;
        text-decoration: none;
    }
    &.link-show-dropdown{
        display: block;
        margin-top: $margin / 1.5;
        font-family: $font-bold;
        font-size: em(20);
        color: $link-yellow;
        &::before{
            font-family: FontAwesome;
            content: '\f101';
            margin-right: $margin / 2.8;
        }
        &.js-change-arrow{
            &::before{
                font-family: FontAwesome;
                content: '\f103';
            }
        }
        //media queries
        @include mq('phablet'){
            font-size: em(24);
        }
        @include mq('desktop'){
            font-size: em(28);
            text-align: left;
        }
        &.dark{
            display: inline-block;
            color: $link-dark;
            margin-left: 5%;
            margin-bottom: 2em;
            //media querie
            @include mq('desktop'){
               margin-bottom: 1em;
            }
        }
        //ocultar texto no en sr
        .sr-text{
            position: absolute;
            @include hide-text();
        }
    }
    //términos y condiciones de formulario
    &.link-terms{
        color: lighten($font-dark, 10%);
        text-decoration: underline;
    }
}