//se coge de landing anterior(ahora se aplican algunas variables y se hace algún nested)
.offer-form{
	position: relative;
    font-family: $font-base;
    font-size: $font-size;
    background-color: $bg-light;
     //media queriea
     @include mq('tablet-medium'){
        width: 100%;
        min-height: 805px;
        margin-left: auto;
        margin-right: auto;
        font-size: $font-size + 0.2;
    }
    @include mq('desktop'){
        min-height: 790px;
    }
    label{
        width: 100%;
        min-height: 20px;
        @include order(1);
        margin-bottom: $margin / 1.5;
        font-size: em(22);
        color: $font-dark;
        text-align: center;
        //media querie
        @include mq('tablet'){
            width: 40%;
            min-width: 190px;
            margin-bottom: 4px;
            text-align: left;
        }
    }
    .form-block input,
    .form-block textarea{
        width: 100%;
        max-width: 520px;
        @include order(2);
        padding-bottom: 4px;
        border: 0;
        border-bottom: $border-form;
        font-family: $font-base;
        font-size: em(20);
        text-align: center;
        //media querie
        @include mq('tablet'){
            width: calc(100% - 195px);
        }
    }
    input + label{
        @include hide();
        -webkit-animation: input-fade 0.2s;
        -moz-animation: input-fade 0.2s;
        -ms-animation: input-fade 0.2s;
        -o-animation: input-fade 0.2s;
        animation: input-fade 0.2s;
        color: $font-dark;
        //para ie10
        @media all and (-ms-high-contrast:none) {
            @include show();
            max-width: 220px;
        }
    }
    input::-ms-clear{
        @include hide()
    }
    input:focus + label,
    input:not(:placeholder-shown) + label,
    textarea:focus + label,
    textarea:valid + label,
    textarea:not(:placeholder-shown) + label {
        @include show();
    }
    input:invalid,
    textarea:invalid{
        border-bottom: $border-invalid;
        background-color: transparent;
    }
    input:focus:invalid,
    textarea:focus:invalid{
        background-color: transparent;
        border-bottom: $border-invalid;
        color: $font-invalid;
    }
    input:invalid + label,
    textarea:invalid + label{
        color: $font-invalid;
    }
    input:focus:invalid + label::after,
    textarea:focus:invalid + label::after{
        content: " (" attr(data-help) ")";
        -webkit-animation: input-help 100s;
        -moz-animation: input-help 100s;
        -ms-animation: input-help 100s;
        -o-animation: input-help 100s;
        animation: input-help 100s;
    }
    input:invalid + label::after,
    textarea:invalid + label::after {
        content: " (" attr(data-help) ")";
        @include show();
        min-height: 20px;
        position: absolute;
        left: 50%;
        @include translate(-50%, 0);
        font-size: em(9.5);
        color: $font-invalid;
        //media querie
        @include mq('tablet'){
            left: 0;
            @include translate(0, 0);
        }
    }
    input:valid,
    textarea:valid{
        border-bottom: $border-form;
        background-color: transparent;
    }
    input:focus:valid,
    textarea:focus:valid {
        border-bottom: $border-form;
        background-color: transparent;
    }
    input:valid + label,
    textarea:valid + label {
        color: $font-dark;
    }
    input:placeholder-shown,
    textarea:placeholder-shown{
        background-color: transparent;
        border-bottom: 1px solid $placeholder-color;
    }
    input::placeholder,
    textarea::placeholder {
        color: $placeholder-color;
    }
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: $placeholder-color;
        text-transform: uppercase;
    }
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder,
    input::-ms-input-placeholder,
    textarea::-ms-input-placeholder {
        color: $placeholder-color;
        text-transform: uppercase;
    }
    input::-moz-placeholder,
    textarea::-moz-placeholder {
        color: $placeholder-color;
        text-transform: uppercase;
    }
    input:focus::placeholder,
    textarea:focus::placeholder {
        color: transparent;
    }
    input:focus::-webkit-input-placeholder,
    textarea:focus::-webkit-input-placeholder {
        color: transparent;
    }
    input:focus:-ms-input-placeholder,
    textarea:focus:-ms-input-placeholder,
    input:focus::-ms-input-placeholder,
    textarea:focus::-ms-input-placeholder {
        color: transparent;
    }
    input:focus::-moz-placeholder,
    textarea:focus::-moz-placeholder {
        color: transparent;
    }
    /*quitamos background amarillo autocompletado de chrome*/
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
    .required-text{
        margin-bottom: $margin * 2;
        font-size: em(16);
        color: lighten($font-dark, 20%);
    }
    /*estilos checkboxes*/
    .checkbox{
        max-width: 300px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        @include flexbox();
        @include align-items(center);
        margin-bottom: $margin * 1.5;
        //media queries
        @include mq('tablet'){
            max-width: 100%;
        }
    }
    .checkbox label{
        width: 100%;
        height: 20px;
        line-height: 150%;
        padding-left: 35px;
        margin: 0 0 $margin 0;
        font-size: em(16);
        color: $font-dark;
        cursor: pointer;
        //media queries
        @include mq('tablet'){
            margin: 0;
        }
        @include mq('tablet-medium'){
            line-height: 110%;
        }
        //para ie10
        @media all and (-ms-high-contrast:none) {
            max-width: 100%;
        }
    }
    .checkbox [type="checkbox"]:checked,
    .checkbox [type="checkbox"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    .checkbox [type="checkbox"]:checked + label,
    .checkbox[type="checkbox"]:not(:checked) + label{
        position: relative;
    }
    .checkbox [type="checkbox"]:checked + label::before,
    .checkbox [type="checkbox"]:not(:checked) + label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid $bg-yellow;
        border-radius: 4px;
        background: $bg-light;
    }
    .checkbox [type="checkbox"]:checked + label::after,
    .checkbox [type="checkbox"]:not(:checked) + label::after {
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        top: 3px;
        left: 3px;
        border-radius: 4px;
        @include transition(all 0.2s ease);
        background: $bg-yellow;
    }
    .checkbox [type="checkbox"]:not(:checked) + label::after {
        opacity: 0;
        @include scale(0);
    }
    .checkbox [type="checkbox"]:checked + label:after {
        opacity: 1;
        @include scale(1);
    }
    /*animaciones de inputs*/
    @-webkit-keyframes input-fade {
        0% {
            opacity: 0;
            -webkit-transform: translateY(-1.4em);
        }
        100% {
            opacity: 1;
            -webkit-transform: translateY(0);
        }
    }
    @-moz-keyframes input-fade {
        0% {
            opacity: 0;
            -moz-transform: translateY(-1.4em);
        }
        100% {
            opacity: 1;
            -moz-transform: translateY(0);
        }
    }
    @keyframes input-fade {
        0% {
            opacity: 0;
            @include translate(0, -1.4em);
        }
        100% {
            opacity: 1;
            @include translate(0, 0);
        }
    }
    @-webkit-keyframes input-help {
        0% {
            opacity: 0;
        }
        4% {
            opacity: 0;
        }
        5% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }
    @-moz-keyframes input-help {
        0% {
            opacity: 0;
        }
        4% {
            opacity: 0;
        }
        5% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes input-help {
        0% {
            opacity: 0;
        }
        4% {
            opacity: 0;
        }
        5% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }

}
form{
    &.offer-form__form{
        width: 100%;
        max-width: 740px;
        min-height: 650px;
        padding: $margin * 2 $margin;
        margin-left: auto;
        margin-right: auto;
        //media queriea
        @include mq('tablet-medium'){
            padding: $margin * 2 $margin / 2 $margin * 2 $margin * 2;
        }
        legend{
            font-family: $font-bold;
            font-size: em(30);
            //media querie
            @include mq('tablet-medium'){
                font-size: em(38);
            }
        }
        .form-block{
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(space-between);
            @include align-items(center);
            position: relative;
            margin: $margin * 2.5 0;
            //media querie
            @include mq('tablet'){
                @include flex-direction(row);
            }
        }
    }
}
