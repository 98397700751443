.header-fain{
    width: 100%;
    height: 3.750rem;  //60px
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: $bg-dark;
    font-size: $font-size;
    //media querie
    @include mq('tablet-medium'){
        height: 7.250rem; //116px
        font-size: $font-size + 0.2;
    }
    .wrapper-content{
        height: 100%;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        //media querie
        @include mq('tablet-medium'){
            @include justify-content(flex-end);
        }
    }
    &.politics{
        .wrapper-content{
            @include justify-content(flex-end);
        }
    }
}
.header-fain__left{
    height: 100%;
    font-family: $font-heavy;
    cursor: pointer;
    //media querie
    @include mq('tablet-medium'){
        width: 40%;
        max-width: 26.125rem;
        height: 3.5rem;
        position: absolute;
        left: 0;
        bottom: 0;
        @include align-items(flex-end);
    }
    a{
        width: 100%;
        height: 100%;
        @include flexbox();
        @include align-items(center);
        pointer-events: none;
        overflow: hidden;
        &::after{
            font-family: 'FontAwesome';
            content: '\f044';
            font-size: em(50);
            font-weight: 400;
            color: $font-yellow;
            //media querie
            @include mq('tablet-medium'){
                width: 50px;
                padding: 0.39em;
                font-size: 31px;
                text-align: center;
                color: $font-gray;
                background-color: $bg-dark-gray;
                overflow: hidden;
            }
        }
        span{
            @include hide();
            //media querie
            @include mq('tablet-medium'){
                @include show();
                @include flexbox();
                @include justify-content(center);
                @include align-items(center);
                width: calc(100% - 50px);
                height: 100%;
                font-size: em(20);
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                color: $font-dark;
                background-color: $bg-yellow;
            }
        }
    }
    //para ie10
    @media all and (-ms-high-contrast:none) {
        max-width: 500px;
    }
}
.header-fain__logo{
    width: 5rem; //80px
    position: absolute;
    top: 1em; //15px
    left: 50%;
    @include translate(-50%, 0);
    img{
        @include img;
    }
    //media querie
    @include mq('tablet-medium'){
        width: 9rem;
        top: 0.74em;
    }
    @include mq('desktop'){
      width: 11.250rem; //200px
    }
}
.header-fain__right{
    @extend .header-fain__left;
    @include flexbox();
    @include align-items(center);
    font-family: $font-base;
    //media querie
    @include mq('tablet-medium'){
        width: auto;
        height: auto;
        position: relative;
        text-align: right;
        @include flex-direction(column);
        @include align-items(center);
        cursor: default;
    }
    .header-fain__right__top{
        //media querie
        @include mq('tablet-medium'){
            @include flexbox();
            @include align-items(center);
        }
    }
    a{
        width: auto;
        pointer-events: none;
        //media querie
        @include mq('tablet-medium'){
            width: 100%;
            pointer-events: none;
            cursor: default;
        }
        &::after{
            font-family: 'FontAwesome';
            content: '\f095';
            pointer-events: all;
            cursor: pointer;
            //media querie
            @include mq('tablet-medium'){
                @include hide();
            }
        }
        span{
            width: 100%;
            font-size: em(42);
            color: $font-yellow;
            background-color: transparent;
        }
    }
}
.header-fain__right__text{
   @include hide();
   color: $font-light;
   //media querie
   @include mq('tablet-medium'){
    @include show();
    margin-right: 4px;
    font-size: em(21);
    text-transform: capitalize;
   }
}
.header-fain__right__text2{
    max-width: 50px;
    margin-right: $margin / 3;
    font-size: em(17);
    line-height: 110%;
    color: $font-yellow;
    text-transform: capitalize;
    //media querie
    @include mq('tablet-medium'){
        max-width: 100%;
        font-size: em(21);
        text-transform: capitalize;
    }
}