
//contenedor general
.main-fain{
    width: 100%;
    max-width: $maxwidth-page;
    height: 100%;
    margin-top: 5.6rem;
    margin-left: auto;
    margin-right: auto;
    @include mq('tablet-medium'){
        margin-top: 12.6rem;
    }
    //para ie
    .main-fain--ie{
        @extend .main-fain;
        &:focus{
            outline: 0;
        }
    }
}
//secciones
.main-fain-block{
    position: relative;
    padding: 2em 0;
    font-size: $font-size;
    &.no-padding{
        padding: 0;
    }
    &.second{
        padding: 0;
        background-color: $bg-dark;
        .second__bottom{
            padding: 3em 0;
            background-color: $bg-yellow;
        }
    }
    &.six{
        @extend .second;
        padding: 0;
    }
}
 //dos columnas
 .two-cols{
    .wrapper-content{
        @include flexbox();
        @include flex-direction(column);
        //media querie
        @include mq('tablet-medium'){
            @include flex-direction(row);
            @include justify-content(space-between);
            @include align-items(center);
        }
        .two-cols__left{
            &.list{
                max-height: 100%;
                //media querie
                @include mq('tablet'){
                    @include flexbox();
                    @include flex-wrap(wrap);
                }
                .list__item{
                    margin-top: $margin * 3;
                    margin-bottom: $margin * 2;
                    //media querie
                    @include mq('tablet'){
                        width: calc(50% - 20px);
                        @include flex(0 1 calc(50% - 20px));
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    @include mq('tablet-medium'){
                        margin-top: 15px;
                        margin-bottom: 0;
                    }
                    .img-top{
                        &.list{
                            //media querie
                            @include mq('tablet'){
                                max-width: 105px;;
                                max-height: 92px;
                                margin-bottom: $margin * 2.5;
                            }
                        }
                    }
                }
            }
        }
        .two-cols__right{
            &.bg{
                width: 100%;
                min-height: 367px;
                margin-top: 1em;
                background-image: url(../images/saludo-small.jpg);
                background-repeat: no-repeat;
                background-position: 0 0;
                background-size: cover;
                //media queries
                @include mq('phablet'){
                    min-height: 480px;
                    background-image: url(../images/saludo-med.jpg);
                }
                @include mq('tablet'){
                    margin-top: 0;
                }
                @include mq('desktop'){
                    min-height: 500px;
                    background-image: url(../images/saludo.jpg);
                }
            }
            &.list{
                @extend .bg;
                max-width: 660px;
                margin-left: auto;
                margin-right: auto;
                background-image: url(../images/compromiso-small.jpg);
                //media queries
                @include mq('desktop'){
                    max-width: 760px;
                    min-height: $height-imgCompromiso;
                    margin-right: 0;
                    background-image: url(../images/compromiso.jpg);
                }
            }
            &.yellow{
                background-image: none;
                background-color: $bg-yellow;
            }
        }
    }
    .two-cols__item{
       @include flex(0 1 50%);
        &.two-cols__left{
            padding: 0 1em;
        }
        &.two-cols__right{
            margin-left: 0;
        }
        &.icos-bg{
            margin-top: 1em;
            text-align: center;
            .ico-bg{
                width: calc(50% - 2px);
                max-width: $size-icoBg;
                height: auto;
                min-height: $size-icoBg;
                display: inline-block;
                vertical-align: middle;
                margin-left: $margin;
                margin-right: $margin;
                background-repeat: no-repeat;
                background-position: 0 0;
                background-size: cover;
                //media queries
                @include mq('tablet'){
                    max-width: $size-icoBg + 50;
                    min-height: $size-icoBg + 50;
                }
                &.one{
                    background-image: url(../images/ico-block1.svg);
                }
                &.two{
                    background-image: url(../images/ico-block2.svg);
                }
            }
        }
    }
}
//tres columnas
.three-cols{
    @extend .two-cols;
    .wrapper-content{
        @include align-items(center);
        @include flex-wrap(wrap);
        //media queries
        @include mq('tablet'){
           @include flex-direction(row);
           @include justify-content(flex-start);
           @include align-items(flex-start);
        }
    }
    .three-cols__item{
        @include flex(0 1 100%);
        max-width: 478px;
        margin: $margin * 3 $margin;
        text-align: center;
        //media queries
        @include mq('tablet'){
            @include flex(0 1 46.5%);
        }
        @include mq('desktop'){
            @include flex(0 1 31.333333%);
        }
        .ico-top{
            width: 100%;
            max-width: $size-icoTop;
            height: auto;
            min-height: $size-icoTop;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0.6em;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: cover;
            //media queries
            @include mq('tablet'){
                max-width: $size-icoTop + 20;
                min-height: $size-icoTop + 20;
            }
            &.one{
                background-image: url(../images/ico-people.svg);
            }
            &.two{
                background-image: url(../images/ico-lupa.svg);
            }
            &.three{
                background-image: url(../images/ico-block3.svg);
            }
        }
    }
}
.wrapper-content{
    @include max-width;
    @include push-auto;
    &.bg{
        width: 95%;
        max-width: 95%;
        height: 116px;
        margin-left: 5%;
        background-color: #fff;
        background-image: url(../images/city-small.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        //media quereis
        @include mq('tablet'){
            height: 150px;
            background-image: url(../images/city-med.jpg);
        }
        @include mq('desktop'){
            height: 287px;
            background-image: url(../images/city.jpg);
        }
    }
    &.full{
        overflow-x: hidden;
    }
    &.flex-column{
        @include flex-direction(column);
        @include mq('desktop'){
            @include flex-direction(row);
        }
    }
}
//imagen superior
.img-top{
    width: 100%;
    max-width: 478px;
    margin-bottom: $margin * 1.2;
    &.list{
        max-width: 110px;
        @include push-auto();
        //media querie
        @include mq('tablet'){
            max-width: 200px;
        }
    }
    &.yellow{
        max-width: 150px;
        margin: $margin * 3 auto;
        //media queries
        @include mq('phablet'){
            max-width: 200px;
        }
        @include mq('tablet'){
           max-width: 240px;
           margin-top: $margin * 4;
           margin-bottom: 6em;
        }
        img{
            width: 100%;
        }
    }
}
//bloque desplegable al hacer click en link amarillo
.dropdown-content{
    margin-top: $margin;
    text-align: center;
    //media queries
    @include mq('desktop'){
        text-align: left;
    }
    .dropdown-content__text{
        font-family: $font-base;
        font-size: em(20);
        line-height: 130%;
        text-align: center;
        color: $font-dark;
        //media queries
        @include mq('desktop'){
            font-size: em(25);
            text-align: left;
        }
    }
    &.small{
        width: 95%;
        margin-top: -1em;
        margin-left: 5%;
        margin-bottom: 4em;
    }
}
//bloque opinión feedback de clientes de fain (se coge de landing anterior y se ajusta un poco a sass)
.feedback-content{
    width: 100%;
    //media querie
    @include mq('tablet-medium'){
        @include flexbox();
        @include justify-content(space-between);
    }
}
.feedback-left,
.feedback-right{
     //media querie
     @include mq('tablet-medium'){
         @include flex(0 1 50%);
    }
}
.feedback-left{
    padding: $margin * 2 2%;
    background-color: $bg-yellow;
    //media queries
    @include mq('tablet-medium'){
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
    }
    .feedback-title{
        font-family: $font-heavy;
        font-size: em(32);
        line-height: 100%;
        //media querie
        @include mq('tablet-medium'){
            width: 75%;
            margin-bottom: $margin / 2;
            margin-left: auto;
            margin-right: auto;
            font-size: em(60);
        }
    }
}
.feedback-right{
    .feedback-img{
        width: 100%;
        max-width: 740px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        overflow: hidden;
    }
}
.feedback-people{
    max-width: 100%;
    @include flexbox();
    @include justify-content(space-between);
    @include flex-wrap(wrap);
     //media querie
     @include mq('tablet-medium'){
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
    .feedback-people-item{
        width: 100%;
		margin-top: $margin * 1.2;
        margin-bottom: $margin * 1.2;
        .feedback-name{
            margin-bottom: $margin / 1.5;
            font-family: $font-bold;
            font-size: em(21);
             //media querie
            @include mq('tablet-medium'){
                font-size: em(30);
            }
        }
        .feedback-text{
            margin-bottom: $margin / 2;
            font-size: em(19);
            line-height: 130%;
             //media querie
             @include mq('tablet-medium'){
                font-size: em(22);
            }
        }
    }
}
