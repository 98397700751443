.footer-fain{
    @extend .header-fain;
    height: auto;
    position: relative;
     //media querie
     @include mq('phablet'){
        font-size: $font-size + 0.1;
    }
    .wrapper-content{
        @include flex-direction(column);
        padding: 1em 0;
        //media querie
        @include mq('phablet'){
            @include flex-direction(row);
            @include justify-content(space-between);
        }
    }
}
.footer-fain__left{
    margin-bottom: 1.2em;
    text-align: center;
    //media querie
    @include mq('phablet'){
        margin-bottom: 0;
        text-align: left;
    }
    p{
        margin-bottom: 0.4em;
        font-size: em(18);
        color: $font-gray;
        &:last-child{
            margin-bottom: 0;
        }
    }
    a{
        @extend p;
        display: block;
        color: $font-light;
    }
}
.footer-fain__right{
    .footer-fain__right__top{
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        //media querie
        @include mq('tablet'){
            @include justify-content(flex-end);
        }
    }
    p{
        margin-bottom: 0.4em;
        font-size: em(18);
        text-align: center;
        text-transform: capitalize;
        //media querie
        @include mq('tablet'){
            text-align: right;
        }
        &.footer-fain__right__text{
            color: $font-light;
        }
        &.footer-fain__right__text2{
            color: $font-yellow;
        }
    }
    a{
        @extend p;
        display: block;
        margin-bottom: 0;
        font-size: em(24);
        color: $link-yellow;
        &[href*="tel"]{
            //media querie
            @include mq('tablet'){
                pointer-events: none;
            }
        }
    }
}