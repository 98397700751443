button{
    &:hover{
        @include opacity($opacity);
    }
    &.btn-form{
        width: 100%;
        max-width: 300px;
        height: 45px;
        position: relative;
        display: block;
        margin: $margin * 1.5 auto 0;
        padding-left: 0;
        text-align: center;
        font-size: em(21);
        cursor: pointer;
        border: 1px solid $bg-yellow;
        background-color: $bg-yellow;
        //media querie
        @include mq('tablet'){
            position: absolute;
            bottom: 0;
            right: 0;
            padding-left: 30px;
            text-align: left;
        }
    }
}